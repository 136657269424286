import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiContext } from 'providers';
import Modal from '../Modal';
import CoreUser from 'components/CoreUser';

const STATE_WAITING = 'waiting';
const STATE_LOADING = 'loading';
const STATE_DONE = 'done';
const STATE_ERROR = 'error'

const { useApi } = ApiContext;

function CoreUsers({ isShown, onClose }) {
  const { request } = useApi();
  const [currentState, setCurrentState] = useState(STATE_WAITING);
  const [users, setUsers] = useState(null)
  const [selectedUser, setSelectedUser] = useState('');
  const [filterPartner, setFilterPartner] = useState('');

  useEffect(() => {
    if (isShown && currentState === STATE_WAITING) {
      (async () => {
        setCurrentState(STATE_LOADING);
        try {
          const data = await request('/gk/core/users')
          setUsers(data);
          setCurrentState(STATE_DONE);
        } catch {
          setCurrentState(STATE_ERROR)
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown, currentState]);

  const handleFilterText = (e) => {
    setFilterPartner(e.target.value);
  };

  const handleCollapse = (accountId) => {
    if (selectedUser !== accountId) {
      setSelectedUser(accountId);
    } else {
      setSelectedUser('');
    }
  };

  return (
    <Modal isShown={isShown} onClose={onClose} title="Core Users">
      <>
      {currentState === STATE_DONE && users && (
        <>
          <div className="container-fluid my-5">
            <div className="row">
              <div className="col-md-auto">
                <label
                  htmlFor="partner-filter"
                  className="form-label my-1 fs-5"
                >
                  Filter by Partner:
                </label>
              </div>
              <div className="col-3">
                <input
                  name="partner-filter"
                  className="form-control"
                  type="text"
                  value={filterPartner}
                  onChange={handleFilterText}
                />
              </div>
            </div>
          </div>
          <div id="core-users-accordion" className="accordion">
            {users.map(({ accountId, name, email }) => (
              <CoreUser
                key={accountId}
                accountId={accountId}
                name={name}
                email={email}
                filterPartner={filterPartner}
                selectedUser={selectedUser}
                onCollapse={handleCollapse}
              />
            ))}
          </div>
        </>
      )}
      {(currentState === STATE_WAITING || currentState === STATE_LOADING) && (
        <div className="m-5 text-center fs-3">
          <FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
        </div>
      )}
      {currentState === STATE_ERROR && (
        <div className="alert alert-danger">
          There was an issue loading the users.
        </div>
      )}
      </>
    </Modal>
  );
}

export default CoreUsers;
