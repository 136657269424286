import { useEffect, useState } from 'react';
import { ApiContext } from 'providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { useApi } = ApiContext;

function CoreUser({
  accountId,
  name,
  email,
  filterPartner,
  selectedUser,
  onCollapse,
}) {
  const { request } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const isSelected = selectedUser === accountId;

  useEffect(() => {
    request(`/gk/core/users/${accountId}/partners`).then((data) => {
      data.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
      setPartners(data);
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filteredPartners = partners;
  if (filterPartner && filterPartner.length >= 3) {
    filteredPartners = partners.filter((p) => {
      const nameFilter = p.names.filter((n) =>
        n.toLowerCase().includes(filterPartner),
      );
      return nameFilter.length > 0;
    });
  }

  const buildStackList = (stacks) => {
    const output = [];
    for (let idx = 0; idx < stacks.length; idx += 1) {
      output.push(
        <span key={idx} className="pe-2 fs-7">
          {`${stacks[idx]}${idx === stacks.length - 1 ? '' : ','}`}
        </span>,
      );
    }
    return output;
  };

  const buildNameList = (name, names) => {
    const output = [];

    for (let idx = 0; idx < names.length; idx += 1) {
      if (names[idx] !== name) {
        output.push(
          <span key={idx} className="pe-2">
            {`${names[idx]}${idx === names.length - 1 ? '' : ','}`}
          </span>,
        );
      }
    }

    if (output.length > 0) {
      return [
        <span key="-1" className="pe-2">
          AKA:
        </span>,
        ...output,
      ];
    }

    return output;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
    {(isLoading || filteredPartners.length > 0) && (
        <div className="accordion-item">
          <h2 className="accordion-header" id={`heading-${accountId}`}>
            <button
              className={`accordion-button${isSelected ? '' : ' collapsed'}`}
              type="button"
              aria-expanded={isSelected ? 'true' : 'false'}
              onClick={() => onCollapse(accountId)}
            >
              <div className="container ms-0">
                <div className="row">
                  <div className="col">
                    <div className="fs-4">{name}</div>
                    <div className="fs-6">({email})</div>
                  </div>
                  <div className="col m-auto">
                    {!isLoading && (
                      <div className="fs-5">
                        partners: {filteredPartners.length}
                      </div>
                    )}
                    {isLoading && (
                      <FontAwesomeIcon icon={['fas', 'circle-notch']} spin />
                    )}
                  </div>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={`collapse-${accountId}`}
            className={`accordion-collapse bg-light collapse${
              isSelected ? ' show' : ''
            }`}
            aria-labelledby={`heading-${accountId}`}
            data-bs-parent="#core-users-accordion"
          >
            <div className="accordion-body">
              {!isLoading && (
                <div className="container-fluid p-0 m-0">
                  {filteredPartners.map(
                    ({ name, names, partnerId, stacks }) => (
                      <div
                        key={partnerId}
                        className="row px-5 py-3 border-bottom"
                      >
                        <div className="col fs-5">{name}</div>
                        <div className="col text-info">
                          {buildNameList(name, names)}
                        </div>
                        <div className="col">{buildStackList(stacks)}</div>
                      </div>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CoreUser;
